/**
 * コモンクラス
 * @constructor
 */
class SpAcordion {
    constructor() {

    }
    /**
     * コモン
     */
    init() {
        let $question = $(".question");
        $question.click(function () {
            $question.not(this).removeClass("is-opened");
            $question.not(this).next().slideUp(300);
            $(this).toggleClass("is-opened");
            $(this).next().slideToggle(300);
        });
    }

}

export default SpAcordion;
