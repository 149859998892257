import ScrollMagic from "scrollmagic";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { gsap, Power3 } from "gsap";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);

const controller = new ScrollMagic.Controller;

import uaNum from "./uaJudgment.js";

/**
 * サンプルクラス
 * @constructor
 */
class Sample {
    constructor() {

    }
    /**
     * サンプル
     */
    init() {
        let videoH,pTopReadH,videoReadH,pTopWwcH,pTopMemberH,pTopNewsH,trigger01,trigger02,trigger03,trigger04,trigger05,triggerUp01,triggerUp02,triggerUp03,triggerUp04,
            wh = $(window).height(),
            current_pos;
        
        // オブジェクト
        let $mScrollMark = $('.m-scrollMark'),
            $mGototop = $('.m-gototop');

        const wwcTtl = new TimelineMax(); // 「WHAT WE CAN DO」のタイトルアニメーション
        const memberTtl = new TimelineMax(); // 「MEMBER」のタイトルアニメーション
        const caseAnim = new TimelineMax(); // .p-topWwc__contBoxのcase01～3と画像のアニメーション
        const contBox02Anim = new TimelineMax(); // .p-topWwc__contBox02のアニメーション
        const newsTtl = new TimelineMax(); // 「NEWS」のタイトルアニメーション
        const aboutTtl = new TimelineMax(); // 「ABOUT」のタイトルアニメーション


        $(window).scroll(function () {
            current_pos = $(this).scrollTop();
            if (current_pos > 200) {
                $mScrollMark.fadeOut();
                $mGototop.fadeIn();
            } else {
                $mScrollMark.fadeIn();
                $mGototop.fadeOut();
            }
        });


        if (uaNum == 1) { //SPだった場合
            let start_pos = 0;
            $(window).scroll(function () {
                videoH = $('#video_pc').height(),
                pTopReadH = $('.p-top_read').height(),
                videoReadH = videoH + pTopReadH
                pTopWwcH = $('.p-topWwc').height(),
                pTopMemberH = $('.p-topMember').height(),
                pTopNewsH = $('.p-topNews').height(),

                trigger01 = (videoReadH)-(wh); // .p-topReadが画面に入ったら
                trigger02 = (videoReadH + pTopWwcH)-(wh+30); // .p-topMemberが画面に入る100px前
                trigger03 = (videoReadH + pTopWwcH)-(wh); // .p-topMemberが画面に入ったら
                trigger04 = (videoReadH + pTopWwcH + pTopMemberH)-(wh); // .p-topNewsが画面に入る100px前
                trigger05 = (videoReadH + pTopWwcH + pTopMemberH + pTopNewsH) - (wh); // .p-topNewsが画面に入ったら
                triggerUp01 = (videoReadH + pTopWwcH + pTopMemberH + pTopNewsH)-800;
                triggerUp02 = (videoReadH + pTopWwcH + pTopMemberH)-1300;
                triggerUp03 = (videoReadH + pTopWwcH + pTopMemberH)-1600;
                triggerUp04 = (videoReadH);

                // console.log(videoReadH);

                if (current_pos > start_pos) { //スクロールダウン
                    console.log('down');
                    if (current_pos > trigger01 && current_pos <= trigger02) {
                        $('.main_bg_ttl01').addClass('isActive');
                    } else if (current_pos > trigger02 && current_pos <= trigger03) {
                        $('.main_bg_ttl01').removeClass('isActive');
                    } else if (current_pos > trigger03 && current_pos <= trigger04) {
                        $('.main_bg_ttl01').removeClass('isActive');
                        $('.main_bg_ttl02').addClass('isActive');
                    } else if (current_pos > trigger05) {
                        console.log('trigger04');
                        $('.main_bg_ttl01').removeClass('isActive');
                        $('.main_bg_ttl02').removeClass('isActive');
                    }
                } else { //スクロールアップ
                    console.log('up');

                    if (current_pos < triggerUp01 && current_pos >= triggerUp02) {
                        $('.main_bg_ttl02').addClass('isActive');
                    } else if (current_pos < triggerUp02 && current_pos >= triggerUp03) {
                        $('.main_bg_ttl02').removeClass('isActive');
                    } else if (current_pos < triggerUp03 && current_pos >= triggerUp04) {
                        $('.main_bg_ttl02').removeClass('isActive');
                        $('.main_bg_ttl01').addClass('isActive');
                    } else if (current_pos < triggerUp04) {
                        $('.main_bg_ttl02').removeClass('isActive');
                        $('.main_bg_ttl01').removeClass('isActive');
                    }
                }
                start_pos = current_pos;
            });

            // 「私たちにできること」のアニメーション
            wwcTtl.set('.p-topWwc__jpSubTtl--chef', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__jpSubTtl--chef', 1.5, {
                    opacity: 1,
                    left: '0vw',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-top__jpSubTtlLine--chef', 1.5, {
                    opacity: 1,
                    width: '14%',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__jpSubTtl--chef',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(wwcTtl)
            // .addIndicators({
            //     name: "p-topWwc__jpSubTtl--chef"
            // })
            .addTo(controller);

            // .p-topWwc__contBoxのcase01～3と画像のアニメーション
            caseAnim.set('.p-topWwc__contBoxItem--01', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__contBoxItem--01', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__contBoxItem--02', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBoxItem--03', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBoxItemImg', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__contBox',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 150,
                reverse: false,
            })
            .setTween(caseAnim)
            // .addIndicators({
            //     name: "p-topWwc__contBox"
            // })
                .addTo(controller);

            // .p-topWwc__contBox02のアニメーション
            contBox02Anim.set('.p-topWwc__contBox02Item--01', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__contBox02ItemImg', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__contBox02Item--01', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1.5')
                .to('.p-topWwc__contBox02Item--02', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBox02Item--03', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBox02Item--04', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBox02Item--05', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__contBox02',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 150,
                reverse: false,
            })
            .setTween(contBox02Anim)
            // .addIndicators({
            //     name: "p-topWwc__contBox02"
            // })
                .addTo(controller);

            // 「メンバー紹介」のアニメーション
            memberTtl.set('.p-topWwc__jpSubTtl--menber', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__jpSubTtl--menber', 1.5, {
                    opacity: 1,
                    left: '0vw',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-top__jpSubTtlLine--menber', 1.5, {
                    opacity: 1,
                    width: '14%',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__jpSubTtl--menber',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(memberTtl)
            // .addIndicators({
            //     name: "p-topWwc__jpSubTtl--menber"
            // })
                .addTo(controller);

            // NEWSのタイトルアニメーション
            newsTtl.set('.p-topNews_ttl', {
                opacity: 0,
                x: '-80px',
            }, '=0',)
                .to('.p-topNews_ttl', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__jpSubTtl--news', 1.5, {
                    opacity: 1,
                    left: '0vw',
                    ease: Power3.easeOut,
                }, '-=0.5')
                .to('.p-top__jpSubTtlLine--news', 1.5, {
                    opacity: 1,
                    width: '14%',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topNews',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(newsTtl)
            // .addIndicators({
            //     name: "p-topNews_ttl"
            // })
                .addTo(controller);

            // ABOUTのタイトルアニメーション
            aboutTtl.set('.p-topAbout_ttl', {
                opacity: 0,
                x: '-80px',
            }, '=0',)
                .to('.p-topAbout_ttl', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__jpSubTtl--about', 1.5, {
                    opacity: 1,
                    left: '0vw',
                    ease: Power3.easeOut,
                }, '-=0.5')
                .to('.p-top__jpSubTtlLine--about', 1.5, {
                    opacity: 1,
                    width: '14%',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topAbout',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(aboutTtl)
            // .addIndicators({
            //     name: "p-topAbout_ttl"
            // })
                .addTo(controller);

        } else { // PCだった場合

            let start_pos = 0;
            $(window).scroll(function () {
                videoH = $('#video_pc').height(),
                pTopReadH = $('.p-top_read').height(),
                videoReadH = videoH + pTopReadH,
                pTopWwcH = $('.p-topWwc').height(),
                pTopMemberH = $('.p-topMember').height(),
                pTopNewsH = $('.p-topNews').height(),
                trigger01 = (videoReadH)-(wh); // .p-topReadが画面に入ったら
                trigger02 = (videoReadH + pTopWwcH)-(wh+100); // .p-topMemberが画面に入る100px前
                trigger03 = (videoReadH + pTopWwcH)-(wh); // .p-topMemberが画面に入ったら
                trigger04 = (videoReadH + pTopWwcH + pTopMemberH)-(wh); // .p-topNewsが画面に入る100px前
                trigger05 = (videoReadH + pTopWwcH + pTopMemberH + pTopNewsH) - (wh); // .p-topNewsが画面に入ったら
                
                triggerUp01 = (videoReadH + pTopWwcH + pTopMemberH + pTopNewsH)-800;
                triggerUp02 = (videoReadH + pTopWwcH + pTopMemberH)-1300;
                triggerUp03 = (videoReadH + pTopWwcH + pTopMemberH)-1600;
                triggerUp04 = (videoReadH);
                
                // console.log('videoH：' + videoH);
                // console.log('pTopReadH：' + pTopReadH);
                // console.log('trigger01：' + trigger01);
                // console.log('videoH：' + videoH);

                if (current_pos > start_pos) { //スクロールダウン
                    // console.log('down');
                    if (current_pos > trigger01 && current_pos <= trigger02) {
                        // .p-topMemberが画面に入る100px前まで
                        $('.main_bg_ttl01').addClass('isActive');
                    } else if (current_pos > trigger02 && current_pos <= trigger03) {
                        // .p-topMemberが画面に入る100px前～.p-topMemberが画面に入るまで
                        $('.main_bg_ttl01').removeClass('isActive');
                    } else if (current_pos > trigger03 && current_pos <= trigger04) {
                        // .p-topMemberが画面に入ったら～.p-topNewsrが画面に入る100px前
                        $('.main_bg_ttl01').removeClass('isActive');
                        $('.main_bg_ttl02').addClass('isActive');
                    } else if (current_pos > trigger04) {
                        // .p-topNewsrが画面に入る100px前～
                        $('.main_bg_ttl01').removeClass('isActive');
                        $('.main_bg_ttl02').removeClass('isActive');
                    }
                } else { //スクロールアップ
                    // console.log('up');

                    if (current_pos < triggerUp01 && current_pos >= triggerUp02) {
                        $('.main_bg_ttl02').addClass('isActive');
                    } else if (current_pos < triggerUp02 && current_pos >= triggerUp03) {
                        $('.main_bg_ttl02').removeClass('isActive');
                    } else if (current_pos < triggerUp03 && current_pos >= triggerUp04) {
                        $('.main_bg_ttl02').removeClass('isActive');
                        $('.main_bg_ttl01').addClass('isActive');
                    } else if (current_pos < triggerUp04) {
                        $('.main_bg_ttl02').removeClass('isActive');
                        $('.main_bg_ttl01').removeClass('isActive');
                    }
                }
                start_pos = current_pos;
            });

            // 「私たちにできること」のアニメーション
            wwcTtl.set('.p-topWwc__jpSubTtl--chef', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__jpSubTtl--chef', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-top__jpSubTtlLine--chef', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__jpSubTtl--chef',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(wwcTtl)
            // .addIndicators({
            //     name: "p-topWwc__jpSubTtl--chef"
            // })
                .addTo(controller);

            // .p-topWwc__contBoxのcase01～3と画像のアニメーション
            caseAnim.set('.p-topWwc__contBoxItem--01', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__contBoxItem--01', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__contBoxItem--02', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBoxItem--03', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBoxItemImg', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__contBox',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 150,
                reverse: false,
            })
            .setTween(caseAnim)
            // .addIndicators({
            //     name: "p-topWwc__contBox"
            // })
                .addTo(controller);

            // .p-topWwc__contBox02のアニメーション
            contBox02Anim.set('.p-topWwc__contBox02Item--01', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__contBox02ItemImg', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__contBox02Item--01', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBox02Item--03', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.4')
                .to('.p-topWwc__contBox02Item--05', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.2')
                .to('.p-topWwc__contBox02Item--02', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.5')
                .to('.p-topWwc__contBox02Item--04', 1.5, {
                    opacity: 1,
                    y: '0px',
                    ease: Power3.easeOut,
                }, '-=1.4')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__contBox02',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 150,
                reverse: false,
            })
            .setTween(contBox02Anim)
            // .addIndicators({
            //     name: "p-topWwc__contBox02"
            // })
                .addTo(controller);

            // 「メンバー紹介」のアニメーション
            memberTtl.set('.p-topWwc__jpSubTtl--menber', {
                opacity: 0
            }, '=0',)
                .to('.p-topWwc__jpSubTtl--menber', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-top__jpSubTtlLine--menber', 1.5, {
                    opacity: 1,
                    x: '0',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topWwc__jpSubTtl--menber',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(memberTtl)
            // .addIndicators({
            //     name: "p-topWwc__jpSubTtl--chef"
            // })
                .addTo(controller);

            // NEWSのタイトルアニメーション
            newsTtl.set('.p-topNews_ttl', {
                opacity: 0,
                x: '-80px',
            }, '=0',)
                .to('.p-topNews_ttl', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__jpSubTtl--news', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=0.5')
                .to('.p-top__jpSubTtlLine--news', 1.5, {
                    opacity: 1,
                    x: '-135px',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topNews',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(newsTtl)
            // .addIndicators({
            //     name: "p-topNews_ttl"
            // })
                .addTo(controller);

            // ABOUTのタイトルアニメーション
            aboutTtl.set('.p-topAbout_ttl', {
                opacity: 0,
                x: '-80px',
            }, '=0',)
                .to('.p-topAbout_ttl', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '+=0.5')
                .to('.p-topWwc__jpSubTtl--about', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=0.5')
                .to('.p-top__jpSubTtlLine--about', 1.5, {
                    opacity: 1,
                    x: '0px',
                    ease: Power3.easeOut,
                }, '-=1')
            new ScrollMagic.Scene({
                triggerElement: '.p-topAbout',
                triggerHook: 'onEnter',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(aboutTtl)
            // .addIndicators({
            //     name: "p-topAbout_ttl"
            // })
                .addTo(controller);
        }
    }
};

export default Sample;
