
/**
 * サンプルクラス
 * @constructor
 */
class ChaefMo {
    constructor() {

    }
    /**
     * サンプル
     */
    init() {
        // alert(4649);
        let $contChefListItem = $('.p-topWwc__contChefListItem'),
            $contChefBg = $('.p-topWwc__contChefBg');
        
        $contChefListItem.hover(
            function() {
                let hiuk = $(this).attr('id');
                $contChefBg.removeClass('isActive');
                $('.' + hiuk).addClass('isActive');
                $('.p-topWwc__contChefListItem--text01', this).hide();
                $('.p-topWwc__contChefListItem--text02', this).show();
            },
            function() {
                $contChefBg.removeClass('isActive');
                $('.p-topWwc__contChefListItem--text01', this).show();
                $('.p-topWwc__contChefListItem--text02', this).hide();
            }
        );

    }
};

export default ChaefMo;
